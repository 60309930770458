.flex {
  display: flex;
  &--wrap {
    flex-wrap: wrap;
  }
}

.flex-column {
  flex-direction: column;
  &-md {
    @media screen and (min-width: 768px) {
      flex-direction: column !important;
    }
  }
}

.flex-row {
  flex-direction: row;
  &-md {
    @media screen and (min-width: 768px) {
      flex-direction: row !important;
    }
  }
}

.flex-item {
  &--stretch {
    flex: 1 1 0px;
  }
  &--shrink {
    flex: 0 0 auto;
  }
  &--grow {
    flex-grow: 1;
  }
  &--no-shrink {
    flex-shrink: 0;
    flex-grow: 0;
  }
  &--fullwidth {
    flex: 0 0 100%;
  }
  &--wrap {
    flex-wrap: wrap;
    &-md {
      @media screen and (min-width: 768px) {
        flex-wrap: wrap !important;
      }
    }
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
    &-md {
      @media screen and (min-width: 768px) {
        justify-content: center !important;
      }
    }
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
  &-end {
    justify-content: flex-end;
    &-lg {
      @media screen and (min-width: $width__bp-lg) {
        justify-content: flex-end !important;
      }
    }
  }
}

.align {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-stretch {
    align-items: stretch;
  }
  &-end {
    align-items: flex-end;
    &-md {
      @media screen and (min-width: 768px) {
        align-items: flex-end !important;
      }
    }
  }
}

.align-content {
  &-start {
    align-content: flex-start;
  }
  &-center {
    align-content: center;
  }
  &-stretch {
    align-content: stretch;
  }
  &-end {
    align-content: flex-end;
  }
}


.align-self {
  &-start {
    align-self: flex-start;
  }
  &-center {
    align-self: center;
  }
  &-stretch {
    align-self: stretch;
  }
  &-end {
    align-self: flex-end;
  }
}
