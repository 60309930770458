@import './utils/fonts';
@import './utils/configs';
@import './utils/colors';
@import './utils/mixins';
@import './utils/reset';
@import './utils/flex-classes';
@import './utils/grid';
@import './utils/common';
@import './utils/buttons';
@import './utils/controls';
@import './utils/typography';
@import './utils/layout';
@import './utils/shared';
@import 'react-toastify/dist/ReactToastify.css';

.c_black {
  color: $black;
}

.start-page-wrapper {
  width: 298px;
}

body {
  font-size: 15px;
}
