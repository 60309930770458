@import "src/style/utils/colors";
@import "src/style/utils/configs";

.login {
  &_container {
    padding-bottom: 50px;
    @media screen and (min-width: 600px) {
      padding: 0;
    }
  }

  &-title {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    @media screen and (min-width: 400px) {
      width: unset;
      text-align: left;
      padding-bottom: 0;
    }
  }

  &-btn {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: $b-fontSize-h3;
    border-radius: 36px;
    height: 60px;
    letter-spacing: 0.5px;
    padding: 5px 10px;
    &__login-code {
      border: 2px solid $primary-app-color;
      color: $text-color_primary;
    }
    &__bank-id {
      font-size: $b-fontSize-h3;
      border-radius: 36px;
      height: 60px;
      letter-spacing: 0.5px;
      padding: 15px 20px;
      color: $white;
      justify-content: center;
      fill: $white;
      background: $bank-id-color;
      &--disabled {
        color: $ordinary-grey;
        fill: $ordinary-grey;
        cursor: not-allowed;
      }
    }
  }
}