@import "src/style/utils/colors";

.spinner {
  &__wrapper {
    min-height: 200px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &__item {
    width: 50px;
    height: 50px;
    border: 5px solid transparent;
    border-top: 5px solid $success-color;
    border-radius: 50%;
    animation: spin-loader 1s linear infinite;
  }
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
