@import "src/style/utils/configs";

.footer {
  box-shadow: 0 -4px 8px -8px #807f7f;
}

.footer-holder {
  flex-direction: column;
  @media screen and (min-width: $width__bp-md) {
    flex-direction: row;
  }
}

.footer-item {
  order: 1;
  width: 100%;
  @media screen and (min-width: $width__bp-md) {
    width: 33.3%;
  }
}

.footer-logo {
  order: 0;
  @media screen and (min-width: $width__bp-md) {
    order: 1;
  }
}
