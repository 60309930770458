.logo-wrapper {
  display: flex;
  place-content: center;
  img {
    width: 100%;
  }
}

.logo-wrapper-dashboard {
    width: 10rem;
    margin-left:10px;
}

.nav-item {
  transition: all 150ms linear;
  @media screen and (min-width: $width__bp-md) {
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Component container */
.component-container {
  font-family: "Avenir";
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom:0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  min-height: 40px;
  margin-top:1rem;
  margin-bottom:1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 10px;
  transition: max-height 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; /* Change the duration and timing function to suit your needs */

  @media screen and (min-width: 480px) {
    width: 100%;
    padding:0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top:1rem;
    margin-bottom:1rem;
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    padding:0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top:1rem;
    margin-bottom:1rem;
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    padding:0;
    padding-top: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top:1.2rem;
    margin-bottom:1.2rem;
  }

  &.expanded {
    transition: all 0.3s ease-in-out; /* Change the duration and timing function to suit your needs */
    max-height: 500px; /* Adjust this value based on the maximum height you expect the container to be when expanded */
  }

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .numberCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #288d00;
    border-radius: 50%;
    margin-right: 10px;
  }

  .numberInCircle {
    font-size: 16px;
    font-weight: bold;
  }

  /* Header */
  .onboardingPageheader {
    font-weight: 600;
    margin-right: auto;
    color:black;
  }

  /* Picture */
  .picture {
    width: 90px;
    height: 90px;
    margin-left: auto;
  }



  /* Additional information */
  .additional-info {
    height: 0;
    transition-duration: 1s;
    overflow: hidden;

    &.active {
      transition-duration: 1s;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 3%;
      padding-bottom: 5%;
      height: auto; // Change this value based on the maximum height you expect the additional info to be
    }
  }
}
// Add dropdown-arrow styles here
.dropdown-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height:10px;

  .arrow {
    transition: transform 0.3s ease-in-out;

    &.arrow-expanded {
      transform: rotate(180deg);
    }

    &.arrow-collapsed {
      transform: rotate(0deg);
    }
  }
}

