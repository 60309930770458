$b-fontSize: 14px;
$b-fontSize-h1: 34px;
$b-fontSize-h2: 28px;
$b-fontSize-h3: 20px;
$b-fontSize-h4: 18px;
$b-fontSize-h5: 16px;
$b-fontSize-h6: 14px;
$b-fontSize-h7: 12px;

$border-radius-regular: 4px;

$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-lg: 992;
$grid__bp-xl: 1200;
$width__bp-sm: 576px;
$width__bp-md: 768px;
$width__bp-lg: 992px;
$width__bp-xl: 1200px;
$grid__cols: 12;

$map-grid-props: (
        "": 0,
        "sm": $grid__bp-sm,
        "md": $grid__bp-md,
        "lg": $grid__bp-lg,
        "xl": $grid__bp-xl
);

@function getBreakpoint($bp) {
  @return $bp + 'px';
}

@function getWidth($bp) {
  @return 'width:' + $bp + px;
}
