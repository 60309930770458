@import "src/style/utils/configs";

.logo-with-text {
  .logo {
    height: 65px;
  }
  .form-divider {
    width: 50px;
    height: 6px;
    background-color: #cbd6df;
    border: none;
  }
  .subtitle {
    font-style: italic;
    font-weight: 300;
  }
  .description {
    white-space: pre-line;
  }
}
