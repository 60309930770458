@import "src/style/utils/configs";

.submenu-holder {
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0 4px 6px -6px #222;
  margin: 10px -20px 0;
  @media screen and (min-width: $width__bp-md) {
    margin: 0;
    border: none;
    box-shadow: 7px 7px 4px 2px #a3a0a0;
    white-space: nowrap;
  }
}

.navbar-wrapper {
  position: relative;
  z-index: 5;
}

.navbar {
  background-color: #f9f9f9;
  border: none;
}

.language-wrapper {
  width: 150px;
}

.navbar-mobile {
  transform: translateY(-140%);
  transition: all 300ms ease-in-out;

  &.active {
    transform: translateY(-0%);
    box-shadow: 0 4px 6px -6px #222;
  }
}
