$outline-color: #767676;

button, .button {
  display: inline-block;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-weight: 600;
  font-family: "Avenir";
}

.link-button {
  text-decoration: underline;
  transition: color 300ms ease-in;
  font-size: $b-fontSize-h5;
  font-family: "Avenir";
  &:hover {
    color: $regular-blue;
  }
}

.green {
  color: $primary-app-color;
}

.g_btn {
  border-radius: 36px;
  font-size: $b-fontSize-h3;
  letter-spacing: 0.5px;
  vertical-align: middle;
  height: 60px;
  width: 136px; 
  transition: all 200ms ease-in;
  &:not(:last-child) {
    margin-right: 14px;
  }
  &--app-cta-primary {
    background: $primary-app-color;
    color: $white;
    &:hover {
      color: $text-color_primary;
      background: $thermometer-color-green;
    }
  }
  &--primary {
    background: $primary-color;
    color: $white;
    &:hover {
      color: $text-color_primary;
      background: $thermometer-color-green;
    }
  }
  &-full-width {
    width: 100%;
  }
  &--outlined {
    background: $white;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      color: $text-color_primary;
      background: $thermometer-color-green;
      border: 1px solid $text-color_primary;
    }
  }
  &--disabled {
    background: $ordinary-grey;
    color: $white;
    border: 1px solid $outline-color;
    cursor: not-allowed;
    &:hover {
      color: $white;
      background: $ordinary-grey;
    }
  }
  &--section {
    border: 1px solid;
    min-height: 24px;
    min-width: 136px;
    margin: 6px 0;
    width: unset;
  }
}

.main-cta_btn {
  border-radius: 50px;
  font-size: $b-fontSize-h3;
  vertical-align: middle;
  min-height: 50px;
  min-width: 200px;
  background: $primary-color;
  color: $white;
  height: auto;
  &.cta-disabled {
    background: $outline-color;
    color: $white;
    border: 1px solid $outline-color;
    cursor: not-allowed;
  }
  &:not(.cta-disabled):hover {
    color: $text-color_primary;
    background: $thermometer-color-green;
  }
}
