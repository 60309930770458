@import "src/style/utils/colors";

.booking {
  &__time-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    height: 40px;
    border-radius: 30px;
    font-size: 16px;
    transition: background-color 200ms ease-in;
    cursor: pointer;
    font-weight: 600;
    &:hover {
      background-color: $primary-color;
      color:white;
    }
    &--selected {
      position: relative;
      background-color: $primary-color;
      border-color: $primary-color;
      color: white;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateX(10px);
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1px solid $success-color;
        border-radius: 50%;
        background-image: url("../../asset/img/icon/check-mark-success.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 70% 70%;
      }
    }
  }
  &__month-btn {
    cursor: pointer;
    color: $success-color;
    transition: color 200ms ease-in;
    padding: 0;
    width: 50px;
    height: 50px;
    &:hover {
      color: $thermometer-color-green;
    }
  }
}
