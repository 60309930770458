.free-card-stripeDesign {
  align-items: center;
  width:100%;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background-color: #f1f1f1;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #6d6e78;
  transition:  .15s ease, border .15s ease, box-shadow .15s ease;
  text-decoration: none;
  text-align: center;
  letter-spacing:0.03em;
}