@import "src/style/utils/colors";
@import "src/style/utils/configs";

@mixin border-radius-regular() {
  border-radius: $border-radius-regular;
}

@mixin common-input-field() {
  color: $text-color_primary;
  border: 1px solid $light-grey;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-color_additional;
  }
}
