$white: #ffffff;
$black: #000;
$bg-color_primary: #f4f6f9;

$primary-color: #529E00;
$primary-app-color: #4E7D1C;
$primary-color-dark: #407a00;
$secondary-color: #739ff4;
$regular-blue: #0017e8;
$primary-color_light: #d8f4c0;
$light-red: #e45e5e;

$text-color_primary: $black;
$text-color_secondary: #455a64;
$text-color_additional: #545e6f;
$text-color_grey: #595959;

$dark-grey: #424242;
$light-grey: #d3d3d3;
$ordinary-grey: #c4c4c4;
$dark-grey: #cbd6df;

$border-color: #e4e4e4;
$border-color_primary: #e0e0e0;
$inputs-border-color: #e3e3e3;

$placeholder-color: #b2b2b2;
$label-color: $light-grey;

$error-color: #BA1717;
$warning-color: #FA5001;
$success-color: $primary-color;
$dark-blue: #546E7A;

$thermometer-color-green: #92DE50;
$thermometer-color-yellow: #FFE500;
$thermometer-color-orange: #FA5001;

$bank-id-color: #193E4F;
