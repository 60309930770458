.slider {
  position: absolute;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #71aa33;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #71aa33;
  height: 5px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
.wrapper {
  background: $white;
  min-height: 100vh;
  width: 100%;
}

.container {
  margin: 0 auto;
  width: 100%;
  @media screen and (min-width: $width__bp-sm) {
    width: $width__bp-sm;
  }
  @media screen and (min-width: $width__bp-md) {
    width: $width__bp-md;
  }
  @media screen and (min-width: $width__bp-lg) {
    width: $width__bp-lg;
  }
  @media screen and (min-width: $width__bp-xl) {
    width: $width__bp-xl;
  }
}

.main-content-wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .page-wrapper {
    padding: 10px 16px 0;
    overflow-y: auto;
    flex: 1 1 0;
  }

  @media screen and (min-width: $width__bp-xl) {
    width: $width__bp-xl;
    margin: 0 auto;
    overflow-y: auto;

    .page-title,
    .page-wrapper {
      &.wider {
        max-width: 1400px;
      }
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      overflow: visible;
      max-height: 100%;
      margin: {
        left: auto;
        right: auto;
      }
    }

    .page-wrapper {
      padding: 20px 0 0;
    }
  }
}

.page-title {
  font-size: 20px;
  position: relative;

  @media screen and (max-width: $width__bp-xl) {
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 16px 20px;
    text-align: center;
  }
}
