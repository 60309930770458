input,
textarea,
select {
  @include common-input-field();
  font-size: $b-fontSize-h3;
  padding: 24px 18px;
}

select {
  background: transparent;
}

.disabled {
  opacity: 0.5;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-holder {
  letter-spacing: 0.6px;
  label {
    color: $text-color_primary;
    display: block;
    line-height: 16px;
  }
}

.input-wrapper {
  position: relative;
  input {
    padding-right: 30px;
  }
  > span {
    color: $placeholder-color;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    text-align: center;
    text-transform: uppercase;
  }
}




