.h1 {
  font-size: $b-fontSize-h1;
}

.h2 {
  font-size: $b-fontSize-h2;
}

.h3 {
  font-size: $b-fontSize-h3;
}

.h4 {
  font-size: $b-fontSize-h4;
}

.h5 {
  font-size: $b-fontSize-h5;
}

.h6 {
  font-size: $b-fontSize-h6;
}

.h7 {
  font-size: $b-fontSize-h7;
}

.t {
  &_uppercase {
    text-transform: uppercase;
  }
  &_right {
    text-align: right;
    &-md {
      @media screen and (min-width: $width__bp-md) {
        text-align: right !important;
      }
    }
  }
  &_left {
    text-align: left;
    &-sm {
      @media screen and (min-width: $width__bp-sm) {
        text-align: left !important;
      }
    }
    &-md {
      @media screen and (min-width: $width__bp-md) {
        text-align: left !important;
      }
    }
  }
  &_center {
    text-align: center;
    &-md {
      @media screen and (min-width: $width__bp-md) {
        text-align: center !important;
      }
    }
  }
  &_size_16 {
    font-size: 16px !important;
  }
  &_bold {
    font-weight: 600;
  }
}

.t {
  &_success {
    color: $success-color;
  }
  &_error {
    color: $error-color;
  }
  &_warning {
    color: $warning-color;
  }
}

.t {
  &_bold {
    font-weight: 700;
  }
  &_normal {
    font-weight: 400;
  }
}

.t {
  &_italic {
    font-style: italic;
  }
}

.c {
  &_black {
    color: $text-color_primary;
  }
  &_grey {
    color: $text-color_grey;
  }
}

.t {
  &_lightText {
    color:$placeholder-color;
    font-style: italic;
  }
}
